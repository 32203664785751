import { IonButton, IonLabel, useIonModal } from "@ionic/react";
import { useHistory } from "react-router";
import AuthModal from "../AuthModal";

export const SigninButton: React.FC<{
  fill?: any;
}> = ({fill = "outline"}) => {
  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(AuthModal, {
    onDismiss: handleDismiss,
    history: useHistory()
  });

  return (
    <>
      <IonButton fill={fill} onClick={() => {
        present({});
      }}>
        <IonLabel>Sign In</IonLabel>
      </IonButton>
    </>
  )
}