import { Component } from 'react'
import { IonContent, IonPage, IonRow, IonCol, IonCard, IonCardContent, IonLabel, IonItem, IonInput, IonIcon, IonCardHeader, IonCardTitle, IonTextarea, IonSelect, IonSelectOption } from "@ionic/react"
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import Alert from 'react-bootstrap/Alert'
import Spinner from '../components/Spinner';
import { createForm } from '../graphql/mutations';

export default class Contact extends Component<any,
    {
        type: string,
        name: any,
        email: any,
        description: any,
        required: boolean,
        requiredSuccess: boolean,
        alertMessage: any,
        loading: boolean,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            type: props.type || "general",
            name: "",
            email: "",
            description: "",
            alertMessage: "",
            required: false,
            requiredSuccess: false,
            loading: false
        }
    }
    onNameChange = (e: any) => {
        this.setState({
            name: e.target.value,
        });
    };
    onEmailChange = (e: any) => {
        this.setState({
            email: e.target.value,
        });
    };
    onDescChange = (e: any) => {
        this.setState({
            description: e.target.value,
        });
    };
    onSelectFile = (e: any) => {

    };
    isValid = () => {
        if (this.state.name == "") {
            this.setState({
                required: true,
                alertMessage: "Name"
            })
            setTimeout(() => {
                this.setState({
                    required: false
                })
            }, 2000);
            return false
        }
        if (this.state.email == "") {
            this.setState({
                required: true,
                alertMessage: "Email"
            })
            setTimeout(() => {
                this.setState({
                    required: false
                })
            }, 2000);
            return false
        }
        if (this.state.description == "") {
            this.setState({
                required: true,
                alertMessage: "Description"
            })
            setTimeout(() => {
                this.setState({
                    required: false
                })
            }, 2000);
            return false
        }
    }
    onSubmitClick = async () => {

        if (this.isValid() == false) {
            return
        }
        this.setState({
            loading: true,
        })

        let requestServiceQuery: any;
        requestServiceQuery = await API.graphql({
            query: createForm,
            variables: {
              input: {
                name: this.state.name,
                email: this.state.email,
                type: this.state.type,
                message: this.state.description
              }
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        console.log(requestServiceQuery);
        this.setState({
            required: false,
            requiredSuccess: true,
            loading: false,
            name:"",
            email:"",
            description:""
        })
        setTimeout(() => {
            this.setState({
                requiredSuccess: false
            })
        }, 3000);
        
    }
    render() {
        return (
            <>
            <Alert show={this.state.required} variant="warning">
                    <i className="bi bi-exclamation-lg"></i> Please enter {this.state.alertMessage}
                </Alert>
                <Alert show={this.state.requiredSuccess} variant="success">
                    <i className="bi bi-bookmark-check"></i> Your request has been received successfully, we'll reach out soon!
                </Alert>
                <IonPage>
                    <IonContent style={{ '--background': 'transparent' }}>
                    {
                            this.state.loading ? <Spinner /> : ""
                        }
                        <IonRow style={{ minHeight: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <IonCol size="12" sizeMd="8">
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Contact Us</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      Welcome to the modern insurance digitization solution! We can help your business distribute or integrate microservices, just reach out.
                                    </IonCardContent>
                                    <IonItem lines="full">
                                      <IonLabel position="floating">How can we help?</IonLabel>
                                      <IonSelect interface="popover" value={this.state.type} interfaceOptions={{showBackdrop: false}} onIonChange={({detail: {value}}) => {
                                        this.setState({
                                          type: value
                                        })
                                      }}>
                                        <IonSelectOption value="general">General Inquiry</IonSelectOption>
                                        <IonSelectOption value="contributor">Distributing my Service</IonSelectOption>
                                        <IonSelectOption value="customer">Consuming Services</IonSelectOption>
                                        <IonSelectOption value="request">Request New Service</IonSelectOption>
                                        <IonSelectOption value="technical">Technical Support</IonSelectOption>
                                      </IonSelect>
                                    </IonItem>
                                    <IonItem lines="full">
                                        <IonLabel position="floating">What's your name?</IonLabel>
                                        <IonInput
                                            value={this.state.name}
                                            onIonChange={this.onNameChange}
                                            type="text"
                                            placeholder="Your name"
                                            autoCapitalize="words">
                                        </IonInput>
                                    </IonItem>
                                    <IonItem lines="full">
                                        <IonLabel position="floating">What's your email?</IonLabel>
                                        <IonInput
                                            value={this.state.email}
                                            onIonChange={this.onEmailChange}
                                            type="text"
                                            placeholder="Your Email"
                                            autoCapitalize="words">
                                        </IonInput>
                                    </IonItem>
                                    <IonItem lines="full">
                                        <IonLabel position="floating">Tell us more!</IonLabel>
                                        <IonTextarea
                                            value={this.state.description}
                                            onIonChange={this.onDescChange}
                                            placeholder="A description about what you're looking for..."
                                            autoCapitalize="words">
                                        </IonTextarea>
                                    </IonItem>
                                    <IonItem color="primary" button={true} detail={true} onClick={this.onSubmitClick}>
                                        Submit
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonContent>
                </IonPage>
            </>
        )
    }
}
