/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewServiceRequest = /* GraphQL */ `
  query GetNewServiceRequest($id: ID!) {
    getNewServiceRequest(id: $id) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNewServiceRequests = /* GraphQL */ `
  query ListNewServiceRequests(
    $filter: ModelNewServiceRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewServiceRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchNewServiceRequests = /* GraphQL */ `
  query SearchNewServiceRequests(
    $filter: SearchableNewServiceRequestFilterInput
    $sort: SearchableNewServiceRequestSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNewServiceRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const syncNewServiceRequests = /* GraphQL */ `
  query SyncNewServiceRequests(
    $filter: ModelNewServiceRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNewServiceRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getNewContributionRequest = /* GraphQL */ `
  query GetNewContributionRequest($id: ID!) {
    getNewContributionRequest(id: $id) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNewContributionRequests = /* GraphQL */ `
  query ListNewContributionRequests(
    $filter: ModelNewContributionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewContributionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchNewContributionRequests = /* GraphQL */ `
  query SearchNewContributionRequests(
    $filter: SearchableNewContributionRequestFilterInput
    $sort: SearchableNewContributionRequestSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNewContributionRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const syncNewContributionRequests = /* GraphQL */ `
  query SyncNewContributionRequests(
    $filter: ModelNewContributionRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNewContributionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        description
        votes
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceBuildingBlocks = /* GraphQL */ `
  query GetServiceBuildingBlocks($id: ID!) {
    getServiceBuildingBlocks(id: $id) {
      id
      SequenceNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BuildingBlockService {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listServiceBuildingBlocks = /* GraphQL */ `
  query ListServiceBuildingBlocks(
    $filter: ModelServiceBuildingBlocksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceBuildingBlocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SequenceNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchServiceBuildingBlocks = /* GraphQL */ `
  query SearchServiceBuildingBlocks(
    $filter: SearchableServiceBuildingBlocksFilterInput
    $sort: SearchableServiceBuildingBlocksSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchServiceBuildingBlocks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        SequenceNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const syncServiceBuildingBlocks = /* GraphQL */ `
  query SyncServiceBuildingBlocks(
    $filter: ModelServiceBuildingBlocksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceBuildingBlocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SequenceNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetoolOnboard = /* GraphQL */ `
  query GetRetoolOnboard($id: ID!) {
    getRetoolOnboard(id: $id) {
      id
      ApiName
      ShortDescription
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRetoolOnboards = /* GraphQL */ `
  query ListRetoolOnboards(
    $filter: ModelRetoolOnboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetoolOnboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ApiName
        ShortDescription
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRetoolOnboards = /* GraphQL */ `
  query SyncRetoolOnboards(
    $filter: ModelRetoolOnboardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRetoolOnboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ApiName
        ShortDescription
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceConsumptionUsage = /* GraphQL */ `
  query GetServiceConsumptionUsage($id: ID!) {
    getServiceConsumptionUsage(id: $id) {
      id
      stag
      requestId
      apiId
      resourcePath
      resourceId
      httpMethod
      sourceIP
      userAgent
      accountId
      apiKey
      apiKeyId
      requestTime
      latency
      stat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      ServiceSubscription {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listServiceConsumptionUsages = /* GraphQL */ `
  query ListServiceConsumptionUsages(
    $filter: ModelServiceConsumptionUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceConsumptionUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stag
        requestId
        apiId
        resourcePath
        resourceId
        httpMethod
        sourceIP
        userAgent
        accountId
        apiKey
        apiKeyId
        requestTime
        latency
        stat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceConsumptionUsages = /* GraphQL */ `
  query SyncServiceConsumptionUsages(
    $filter: ModelServiceConsumptionUsageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceConsumptionUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        stag
        requestId
        apiId
        resourcePath
        resourceId
        httpMethod
        sourceIP
        userAgent
        accountId
        apiKey
        apiKeyId
        requestTime
        latency
        stat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceSubscription = /* GraphQL */ `
  query GetServiceSubscription($id: ID!) {
    getServiceSubscription(id: $id) {
      id
      ownerType
      keyId
      key
      usagePlan
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Company {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      User {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listServiceSubscriptions = /* GraphQL */ `
  query ListServiceSubscriptions(
    $filter: ModelServiceSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceSubscriptions = /* GraphQL */ `
  query SyncServiceSubscriptions(
    $filter: ModelServiceSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceDocument = /* GraphQL */ `
  query GetServiceDocument($id: ID!) {
    getServiceDocument(id: $id) {
      id
      documentType
      documentUrl
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listServiceDocuments = /* GraphQL */ `
  query ListServiceDocuments(
    $filter: ModelServiceDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentType
        documentUrl
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceDocuments = /* GraphQL */ `
  query SyncServiceDocuments(
    $filter: ModelServiceDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentType
        documentUrl
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPricePlan = /* GraphQL */ `
  query GetPricePlan($id: ID!) {
    getPricePlan(id: $id) {
      id
      name
      description
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPricePlans = /* GraphQL */ `
  query ListPricePlans(
    $filter: ModelPricePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricePlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPricePlans = /* GraphQL */ `
  query SyncPricePlans(
    $filter: ModelPricePlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricePlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventTrigger = /* GraphQL */ `
  query GetEventTrigger($id: ID!) {
    getEventTrigger(id: $id) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEventTriggers = /* GraphQL */ `
  query ListEventTriggers(
    $filter: ModelEventTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventTriggers = /* GraphQL */ `
  query SyncEventTriggers(
    $filter: ModelEventTriggerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventTriggers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getValueChainIdentifier = /* GraphQL */ `
  query GetValueChainIdentifier($id: ID!) {
    getValueChainIdentifier(id: $id) {
      id
      name
      description
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listValueChainIdentifiers = /* GraphQL */ `
  query ListValueChainIdentifiers(
    $filter: ModelValueChainIdentifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValueChainIdentifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncValueChainIdentifiers = /* GraphQL */ `
  query SyncValueChainIdentifiers(
    $filter: ModelValueChainIdentifierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncValueChainIdentifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompanySocialMediaLink = /* GraphQL */ `
  query GetCompanySocialMediaLink($id: ID!) {
    getCompanySocialMediaLink(id: $id) {
      id
      type
      url
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompanySocialMediaLinks = /* GraphQL */ `
  query ListCompanySocialMediaLinks(
    $filter: ModelCompanySocialMediaLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanySocialMediaLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        url
        companyID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanySocialMediaLinks = /* GraphQL */ `
  query SyncCompanySocialMediaLinks(
    $filter: ModelCompanySocialMediaLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanySocialMediaLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        url
        companyID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLineOfBusiness = /* GraphQL */ `
  query GetLineOfBusiness($id: ID!) {
    getLineOfBusiness(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLineOfBusinesses = /* GraphQL */ `
  query ListLineOfBusinesses(
    $filter: ModelLineOfBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineOfBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLineOfBusinesses = /* GraphQL */ `
  query SyncLineOfBusinesses(
    $filter: ModelLineOfBusinessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLineOfBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInsuranceValueChain = /* GraphQL */ `
  query GetInsuranceValueChain($id: ID!) {
    getInsuranceValueChain(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInsuranceValueChains = /* GraphQL */ `
  query ListInsuranceValueChains(
    $filter: ModelInsuranceValueChainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsuranceValueChains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInsuranceValueChains = /* GraphQL */ `
  query SyncInsuranceValueChains(
    $filter: ModelInsuranceValueChainFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsuranceValueChains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserType = /* GraphQL */ `
  query GetUserType($id: ID!) {
    getUserType(id: $id) {
      id
      name
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserTypes = /* GraphQL */ `
  query ListUserTypes(
    $filter: ModelUserTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        companyID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserTypes = /* GraphQL */ `
  query SyncUserTypes(
    $filter: ModelUserTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        companyID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompanyType = /* GraphQL */ `
  query GetCompanyType($id: ID!) {
    getCompanyType(id: $id) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompanyTypes = /* GraphQL */ `
  query ListCompanyTypes(
    $filter: ModelCompanyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanyTypes = /* GraphQL */ `
  query SyncCompanyTypes(
    $filter: ModelCompanyTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceTag = /* GraphQL */ `
  query GetServiceTag($id: ID!) {
    getServiceTag(id: $id) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listServiceTags = /* GraphQL */ `
  query ListServiceTags(
    $filter: ModelServiceTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceTags = /* GraphQL */ `
  query SyncServiceTags(
    $filter: ModelServiceTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        serviceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      description
      currentPartner
      logoUrl
      websiteUrl
      email
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      CompanySocialMediaLinks {
        nextToken
        startedAt
      }
      LineOfBusiness {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      InsuranceValueChain {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      UserTypes {
        nextToken
        startedAt
      }
      CompanyType {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      role
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      type
      tagLine
      description
      liveStatus
      logoURL
      launchDate
      privacyPolicyURL
      owner
      openAPISpecificationUrl
      videoURL
      postmanCollectionUrl
      gatewayAPIId
      targetURL
      authType
      headerKey
      headervalue
      untitledfield
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ServiceDocuments {
        nextToken
        startedAt
      }
      PricePlan {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      EventTriggers {
        nextToken
        startedAt
      }
      ValueChainIdentifiers {
        nextToken
        startedAt
      }
      ServiceTags {
        nextToken
        startedAt
      }
      OwnerCompany {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      OwnerUser {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchServices = /* GraphQL */ `
  query SearchServices(
    $filter: SearchableServiceFilterInput
    $sort: SearchableServiceSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchServices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLastDIBData = /* GraphQL */ `
  query GetLastDIBData($id: ID!) {
    getLastDIBData(id: $id) {
      id
      quoteNumber
      carrierCode
      isWining
      quoteExpiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLastDIBData = /* GraphQL */ `
  query ListLastDIBData(
    $filter: ModelLastDIBDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLastDIBData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quoteNumber
        carrierCode
        isWining
        quoteExpiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchLastDIBData = /* GraphQL */ `
  query SearchLastDIBData(
    $filter: SearchableLastDIBDataFilterInput
    $sort: SearchableLastDIBDataSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLastDIBData(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        quoteNumber
        carrierCode
        isWining
        quoteExpiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const syncLastDIBData = /* GraphQL */ `
  query SyncLastDIBData(
    $filter: ModelLastDIBDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLastDIBData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quoteNumber
        carrierCode
        isWining
        quoteExpiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      name
      email
      type
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        type
        message
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncForms = /* GraphQL */ `
  query SyncForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        type
        message
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
