import { Component } from 'react';


import '../../styleassets/css/bootstrap.min.css';
import '../../styleassets/css/Marketplace.css';
import '../../styleassets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';

import { IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import gql from 'graphql-tag'
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import MarketPlaceSpinner from '../../components/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ServiceDocumentation from './ServiceDocumentation';
import MarketplaceCard from './MarketplaceCard';
import ServiceDetails from './ServiceDetails'

interface RouteParams { index: string }

class ServicePage extends Component<
    RouteComponentProps<RouteParams>,
    { loading: boolean; service: any;  apiId:any; apiType:boolean;bundleservices:any;openAPISpecificationUrl:any}
> {

    constructor(props: any) {
        super(props);
        this.state = {

            service: {},
            loading: false,
            apiId:null,
            apiType:false,
            bundleservices:[],
            openAPISpecificationUrl:null,
        }
    }

    componentDidMount() { 
        this.getBundledService();
    }
    
    getBundledService = async () => {
        this.setState({
            loading: true
        });

        let bundledServices: any = gql`
        query GetBundledServices {
            listServiceBuildingBlocks {
              items {
                BuildingBlockService {
                  id
                  name
                  tagLine
                  type
                  ServiceTags {
                    items {
                      name
                    }
                  }
                  OwnerCompany {
                    name
                  }
                  logoURL
                }
                Service {
                  name
                  id
                }
              }
            }
          }
          ` ;
          
        let bundledServicesDataQuery: any;
        bundledServicesDataQuery = await API.graphql({
            query: bundledServices,            
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });        
        
        const bundleservices = bundledServicesDataQuery.data.listServiceBuildingBlocks.items;
        //console.log(bundleservices);
        if (bundleservices.length > 0) {
            this.setState({
                bundleservices: bundleservices,
                loading: false,                
            });
        }
     
    }

    serviceApiType =(val:boolean,url:any)=>{
        this.setState({
            apiType:val,
            openAPISpecificationUrl:url,
        })
    }
    render() {
        console.log(this.state.openAPISpecificationUrl)
        return (

            <IonPage>
                <IonContent>
                    <Tabs defaultActiveKey="Overview">
                        <Tab eventKey="Overview" title="Overview">
                            <ServiceDetails
                                serviceId={this.props.match.params.index}
                                serviceApiData ={this.serviceApiType}
                            >
                            </ServiceDetails>
                        </Tab>
                        <Tab eventKey="CheckDetails" title="Check Details">
                            <ServiceDocumentation
                            openAPISpecificationUrl={this.state.openAPISpecificationUrl}
                        ></ServiceDocumentation>
                        </Tab>
                        {
                            this.state.apiType?<Tab eventKey="BuildingBlock" title="Building Block">
                            <section className="offer-box-section">
                            <div className="container-fluid">
                                <div className="offer-box-wrapper">
                                    {
                                        this.state.bundleservices.length > 0 ?this.state.bundleservices.map((model: any, index: any) => {
                                            return (
                                                <MarketplaceCard key={model.BuildingBlockService.id} model={model.BuildingBlockService} />
                                            )
                                        }) : <div style={{ textAlign: "center" }}>
                                            <h1>Opp's!</h1>
                                            No Api Found Associated with this Workflow!
                                        </div>

                                    }
                                </div>
                            </div>
                        </section>   
                        </Tab>:""
                        }
                        
                    </Tabs>
                </IonContent>
            </IonPage>


        );
    }
}

export default ServicePage;