import { Component } from 'react';
import { IonSpinner } from '@ionic/react';




class Spinner extends Component<any> {
    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <IonSpinner name="crescent"  ></IonSpinner>
            </div>
        );
    }
}

export default Spinner;