import ParticlesContainer from '../components/ParticlesContainer';

import './Landing.css';
import { IonButton, IonCol, IonContent, IonPage, IonRow, IonFooter, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import MarketplaceSearch from './marketplace/MarketplaceSearch';
import HeroButton from '../components/HeroButton/HeroButton';


function Landing(props: {fsToggle:any}) {
  useIonViewDidEnter(() => props.fsToggle(true));
  useIonViewDidLeave(() => props.fsToggle(false));
  return (
    <>
      <IonPage>
        <ParticlesContainer />
        <IonContent scrollY={false} fullscreen={true} style={{'--background': 'transparent'}} class="ion-padding">
          <IonRow style={{height: '50%'}} className="ion-align-items-end ion-justify-content-center">
            <IonCol size="12" sizeMd="6">
              <MarketplaceSearch />
              {/* <IonSearchbar className="ion-no-padding" placeholder="How can we connect you?"></IonSearchbar> */}
            </IonCol>
          </IonRow>
          <IonRow style={{height: '50%'}} className="ion-align-items-start ion-justify-content-center">
            <IonCol size="6" sizeMd="3">
              <IonButton className="ion-no-margin" expand="block" fill="outline" routerLink="/contribute" routerDirection="forward">
                Contribute
              </IonButton>
            </IonCol>
            <IonCol size="6" sizeMd="3">
              <IonButton className="ion-no-margin" expand="block" fill="solid" routerLink="/explore" routerDirection="forward">
                Explore
              </IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonFooter>
          <HeroButton text="Marketplace" link="/marketplace" />
        </IonFooter>
      </IonPage>
    </>
  )
}

export default Landing;