import { IonFooter, IonItem, IonLabel, IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import './HeroButton.css';

const HeroButton = (props: {text: string; link: string;}) => {
  return (
    <>
      <IonItem className="popup-button" lines="none" style={{'--background': 'transparent'}} button={true} detail={false} routerLink={props.link} routerDirection="root">
        <IonLabel className="ion-text-center ion-text-wrap">
          <p style={{margin: '10px'}}>{props.text}</p>
          <p><IonIcon icon={chevronDown} /></p>
        </IonLabel>
      </IonItem>
    </>
  )
}

export default HeroButton;