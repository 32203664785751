import { IonCard, IonCol, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonRouterLink, IonRow, IonSelectOption, IonSelect } from "@ionic/react"
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from "../../components/MaskedInput";
import { MessageBox } from "../../components/MessageBox";

const RegisterForm = (props:{handler?:any}) => {
  const { control, handleSubmit } = useForm();
  const [msg, setMsg] = useState<{color?:any; message?:any;}>({})
  const register = (values:any) => {
    console.log(values, {
      username: values.email,
      password: values.password,
      attributes: {
        first_name: values.firstName,
        last_name: values.lastName,
        name: values.firstName + ' ' + values.lastName,
        phone_number: values.countryCode + values.phone.replace(/\D/g,'')
      }
    });
    props.handler({loading:true});
    Auth.signUp({
      username: values.email,
      password: values.password,
      attributes: {
        given_name: values.firstName,
        family_name: values.lastName,
        name: values.firstName + ' ' + values.lastName,
        phone_number: values.countryCode + values.phone.replace(/\D/g,'')
      }
    }).then( (resp) => {
      props.handler({view: 'register-confirm'})
    })
    .catch( (error) => {
      setMsg({
        color: 'danger',
        message: error.message
      })
    })
    .finally( () => {
      props.handler({loading: false});
    })
  };
  return (
    <>
      <IonContent color="light">
        <IonCard>
          <IonRow style={{padding: 0}}>
            <IonCol size="6" style={{padding: 0}}>
              <IonItem lines="full">
                <IonLabel position="floating">First Name</IonLabel>
                <Controller
                  control={control}
                  name="firstName"
                  render={
                    ({field: {value, onChange}}) => 
                    <IonInput required value={value} placeholder="Your first name" onIonChange={( { detail: { value } } ) => onChange(value)} type="text"></IonInput>
                  } 
                />
              </IonItem>
            </IonCol>
            <IonCol size="6" style={{padding: 0}}>
              <IonItem lines="full">
                <IonLabel position="floating">Last Name</IonLabel>
                <Controller
                  control={control}
                  name="lastName"
                  render={
                    ({field: {value, onChange}}) => 
                    <IonInput required value={value} placeholder="Your last name" onIonChange={( { detail: { value } } ) => onChange(value)} type="text"></IonInput>
                  } 
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" class="ion-no-padding">
              <IonItem lines="full">
              <IonLabel position="floating">Email</IonLabel>
              <Controller
                control={control}
                name="email"
                render={
                  ({field: {value, onChange}}) => 
                  <IonInput required value={value} placeholder="user@domain.com" onIonChange={( { detail: { value } } ) => onChange(value)} type="email"></IonInput>
                } 
              />
            </IonItem>
            </IonCol>
            <IonCol size="6" sizeLg="3" class="ion-no-padding">
              <IonItem lines="full">
                <IonLabel position="floating">Country Code</IonLabel>
                <Controller
                  control={control}
                  name="countryCode"
                  render={
                    ({field: {value, onChange}}) => 
                      <MaskedInput mask={{mask: '{+}000'}} value={value} onChange={onChange} properties={{
                        placeholder: '+1',
                        type: 'text'
                      }}></MaskedInput>
                  } 
                />
              </IonItem>
            </IonCol>
            <IonCol size="6" sizeLg="9" class="ion-no-padding">
              <IonItem lines="full">
                <IonLabel position="floating">Phone</IonLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={
                    ({field: {value, onChange}}) => 
                    <MaskedInput mask={{mask: '{(}000{)}000{-}0000'}} value={value} onChange={onChange} properties={{
                      placeholder: 'Your phone number',
                      type: 'tel'
                    }}></MaskedInput>
                    // <IonInput required value={value} placeholder="Your phone number" onIonChange={( { detail: { value } } ) => onChange(value)} type="tel"></IonInput>
                  } 
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" class="ion-no-padding">
              <IonItem lines="full">
                <IonLabel position="floating">Password</IonLabel>
                <Controller
                  control={control}
                  name="password"
                  render={
                    ({field: {value, onChange}}) => 
                    <IonInput required value={value} placeholder="Your password" onIonChange={( { detail: { value } } ) => onChange(value)} type="password"></IonInput>
                  } 
                />
              </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                By registering, I agree to InsuranceGIG's <IonRouterLink routerLink="/terms-of-service">Terms of Service</IonRouterLink> and <IonRouterLink routerLink="/privacy-policy">Privacy Policy</IonRouterLink>.
              </IonLabel>
            </IonItem>
            </IonCol>

          </IonRow>
        </IonCard>
        { msg.message ? <MessageBox color={msg.color} message={msg.message} /> : <></> }
      </IonContent>
      <IonFooter>
        <IonItem button={true} lines="none" color="primary" type="submit" detail={true} onClick={handleSubmit(register)}>
          Register
        </IonItem>
      </IonFooter>
    </>
  )
}

export default RegisterForm;