import { Link } from "react-router-dom";

function Logo(props:any = {height: '80px', width: '180px'}) {
  return (
    <Link to="/">
      <div style={{
        width: props.width,
        height: props.height,
        display: 'block',
        margin: '0 auto',
        maxWidth: '350px',
        minWidth: '180px',
        backgroundImage: 'url(assets/logo/insurancegig_logo.png)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer'
      }}></div>
    </Link>
  )
}

export default Logo;