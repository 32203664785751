export const TreeData = {
  name: 'Explore',
  attributes: {
    label: 'for you',
    search: '',
  },
  children: [
    {
      name: "By Line of Business",
      attributes: {
        label: 'by line of business'
      },
      children: [
        {
          name: "Property & Casualty",
          attributes: {
            label: 'for Property & Casualty',
            lob: 'property,casualty',
          },
          children: [
            {
              name: "Personal",
              attributes: {
                label: 'for P&C Personal Lines',
                lob: 'property,casualty',
                search: 'personal',
              },
            },
            {
              name: "Commercial",
              attributes: {
                label: 'for P&C Commercial Lines',
                lob: 'property,casualty',
                search: 'commercial',
              },
              children: [
                {
                  name: "Workers' Compensation",
                  attributes: {
                    label: "for Workers' Compensation",
                    lob: 'property,casualty',
                    search: 'workers compensation',
                  },
                },
                {
                  name: "General Liability",
                  attributes: {
                    label: "for General Liability",
                    lob: 'property,casualty',
                    search: 'general liability',
                  },
                },
                {
                  name: "Small Business",
                  attributes: {
                    label: "for Small Business",
                    lob: 'property,casualty',
                    search: 'business',
                  },
                },
                {
                  name: "Cyber",
                  attributes: {
                    label: "for Cyber",
                    lob: 'property,casualty',
                    search: 'cyber',
                  },
                },
                {
                  name: "Commercial Auto",
                  attributes: {
                    label: "for Commercial Auto",
                    lob: 'property,casualty',
                    search: 'commercial auto',
                  },
                },
                {
                  name: "Product Liability",
                  attributes: {
                    label: "for Product Liability",
                    lob: 'property,casualty',
                    search: 'product liability',
                  },
                },
                {
                  name: "Commercial Property",
                  attributes: {
                    label: "for Commercial Property",
                    lob: 'property,casualty',
                    search: 'commercial property',
                  },
                }
              ]
            },
          ]
        },
        {
          name: "Life",
          children: [
            {name: "Individual Life"},
            {name: "Group Life"},
          ]
        },
        {
          name: "Health",
        },
        {name: "Reinsurance"},
      ]
    },
    {
      name: "By Value Chain",
      children: [
        {name: "Sales and Distribution"},
        {name: "Underwriting"},
        {name: "Pricing"},
        {name: "Payments"},
        {name: "Claims"},
        {name: "Compliance "},
        {name: "Operations "},
        {name: "Loss Control"},
        {name: "Premium Audit"},
        {name: "Servicing"},
        {name: "Other (Add more)"},
      ]
    },
    {
      name: 'By Company Type',
      children: [
        {
          name: 'Agency',
          children: [
            { name: 'Independent' },
            { name: 'Wholesaler' },
            { name: 'MGA' },
            { name: 'Digital Agency' },
          ],
        },
        { name: 'Carrier' },
        {
          name: 'Data Provider',
          children: [
            { name: 'Pricing/Rating' },
            { name: 'Underwriting' },
            { name: 'Property' },
            { name: 'Hazard' },
            { name: 'Psychometrics' },
            { name: 'Predictive' },
          ],
        },
        {
          name: 'Technology Provider',
          children: [
            {
              name: 'By Solution',
              children: [
                { name: 'Agency Management System' },
                { name: 'Middleware' },
                { name: 'Operations' },
                { name: 'Policy Administration' },
              ]
            },
            {
              name: 'By Technology',
              children: [
                { name: 'Analytics' },
                { name: 'Artificial Intelligence' },
                { name: 'Machine Learning' },
                { name: 'Robotic Process Automation' },
                { name: 'Computer Vision' },
                { name: 'Chatbot' },
                { name: 'Mobile' },
                { name: 'Telematics/IoT' },
              ]
            }
          ],
        },
      ],
    },
  ],
};