import { IonButton, IonLabel } from "@ionic/react";
import { Auth } from "aws-amplify";

const SignoutButton = (props: {history:any}) => {
  
  return (
    <IonButton color="danger" expand="block" onClick={(ev) => {
      Auth.signOut()
        .then( (onfulfilled) => {
          window.location.href='/';
        } )
        .catch( (err) => console.error(err) );
    }}>
      <IonLabel>Sign Out</IonLabel>
    </IonButton>  
  )
}

export default SignoutButton;