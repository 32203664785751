import { IonContent, IonFab, IonFabButton, IonFooter, IonIcon, IonPage } from "@ionic/react";
import { chevronUp } from "ionicons/icons";
import { useState } from "react";
import Tree from 'react-d3-tree';
import { TreeNodeDatum } from "react-d3-tree/lib/types/common";
import { useHistory } from "react-router";
import HeroButton from '../../components/HeroButton/HeroButton';
import { TreeData } from "./ExploreData";

const Explore = () => {
  const [translate, setTranslate] = useState<{x:number;y:number}>({x: window.innerWidth / 2, y: window.innerHeight / 2 - 150});
  const [selected, setSelected] = useState<any>({});
  const [level, setLevel] = useState<number>(1);
  const history = useHistory();

  const translateFn = (relative: {x:number; y:number}) => {
    try {
      console.log(relative, translate);
      setTranslate({
        x: (window.innerWidth / 2) - (relative.x - translate.x),
        y: (window.innerHeight / 2 - 150) - (relative.y - translate.y)
      })
    } catch(e) {
      console.error(e);
    }
  }

  const handleNodeClick = (node:TreeNodeDatum, toggleNode:Function) => {
    console.log(node);
    setSelected(node);
    if (!node.children || !node.children.length) {
      history.push(encodeURI(`/marketplace?${node.attributes?.lob ? 'lob=' + node.attributes.lob + '&' : ''}${node.attributes?.search ? 'search=' + node.attributes.search : ''}`));
    } else {
      toggleNode();
    }

    const nodeRect = (document.getElementById(node.__rd3t.id) as any).getBoundingClientRect();
    translateFn({ x: nodeRect.x, y: nodeRect.y });
  }
  
  const browserData = TreeData;
  return (
    <IonPage>
       <IonContent slot="fixed" fullscreen={true} color="light">
        <div style={{height: '100%', width: '100%'}} id="treeWrapper">
          <Tree
            data={browserData}
            translate={translate}
            // pathFunc="step"
            orientation="vertical"
            shouldCollapseNeighborNodes={true}
            enableLegacyTransitions={true}
            initialDepth={level}
            transitionDuration={300}
            separation={{nonSiblings: 1, siblings: 1.5}}
            renderCustomNodeElement={({
              nodeDatum,
              toggleNode,
            }) => {
              const hasChild = Boolean(nodeDatum.children?.length || 0);
              const radius = Math.min((nodeDatum.children?.length || 1) * 8, 20);
              return (
                <g onClick={() => handleNodeClick(nodeDatum, toggleNode)}>
                  <circle fill={String(nodeDatum.attributes?.fill || (hasChild ? 'var(--ion-color-warning' : 'var(--ion-color-success'))} r={radius} />
                  <text fill="black" fontSize="16" strokeWidth="1" x={radius + 5} y={radius/2}>
                    {nodeDatum.name}
                  </text>
                </g>
              )
            }}
          />
        </div>
        <IonFab horizontal="end" vertical="bottom" style={{paddingBottom: '90px'}}>
          <IonFabButton onClick={() => {
            setTimeout(() => {
              const nodeRect = (document.getElementsByClassName("rd3t-node")[0] as any).getBoundingClientRect();
              translateFn({ x: nodeRect.x, y: nodeRect.y });
            }, 300);
          }}>
            <IonIcon icon={chevronUp} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonFooter>
        <HeroButton text={`Marketplace solutions ${selected.attributes?.label || (selected.name ? 'for ' + selected.name : '')}`} link={encodeURI(`/marketplace?${selected.attributes?.lob ? 'lob=' + selected.attributes.lob + '&' : ''}${selected.attributes?.search ? 'search=' + selected.attributes.search : ''}`)} />
      </IonFooter>
    </IonPage>
  )
}

export default Explore;