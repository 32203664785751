import React, { Component } from 'react';
import MarketPlaceCardBox from './MarketPlaceCardBox';
import { withRouter } from 'react-router-dom';


class MarketplaceCard extends Component<any> {
    constructor(props:any) {
        super(props);
        this.state = {          
        }
    } 

    onClickServiceCard= (id:any)=> {
      
       this.props.history.push(`/service/${id}`);
    }

    render() {
    console.log(this.props.model.logoURL)
        return (
            <>
                <div style={{cursor:"pointer"}} className=  "vehicle-box-design" onClick={()=>this.onClickServiceCard(this.props.model.id)}>
                    <h3 style={{ textAlign: "left" }}>{this.props.model.name}</h3>
                    <span className="similar-text">{this.props.model.OwnerCompany.name}</span>
                   
                    <img src={this.props.model.logoURL} alt={this.props.model.name} className="vehicle-image-main" style={{ width: "50px", height: "50px", borderRadius: "20px", cursor: 'pointer' }} />
                    <div className="charges-option-up">
                        <span className="similar-text">{this.props.model.tagLine}</span>
                    </div>
                    {(this.props.model.ServiceTags != null && this.props.model.ServiceTags.items.length > 0) ?

                        (this.props.model.type != null && this.props.model.type.toLowerCase() == "Bundled".toLocaleLowerCase()) ?
                        <div className="important-info-start-bg-stick">
                            <i className="fa fa-star"></i>{this.props.model.ServiceTags.items[0]}
                            <br />
                        </div> 
                        :
                        <div className="important-info-start-bg-apitype">
                            <i className="fa fa-star"></i>{this.props.model.ServiceTags.items[0]}
                            <br />
                        </div> 
                        
                        : 
                        
                        (this.props.model.type != null && this.props.model.type.toLowerCase() == "Bundled".toLowerCase()) ?

                        <div className="important-info-start-bg-stick">
                            {"Workflow"}
                            <br />
                        </div> : <div className="important-info-start-bg-apitype">
                            {"API"}
                            <br />
                        </div>}
                        <MarketPlaceCardBox model={this.props.model} />
                </div> 
            </>
        );
    }
}

export default withRouter(MarketplaceCard);