/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewServiceRequest = /* GraphQL */ `
  mutation CreateNewServiceRequest(
    $input: CreateNewServiceRequestInput!
    $condition: ModelNewServiceRequestConditionInput
  ) {
    createNewServiceRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNewServiceRequest = /* GraphQL */ `
  mutation UpdateNewServiceRequest(
    $input: UpdateNewServiceRequestInput!
    $condition: ModelNewServiceRequestConditionInput
  ) {
    updateNewServiceRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNewServiceRequest = /* GraphQL */ `
  mutation DeleteNewServiceRequest(
    $input: DeleteNewServiceRequestInput!
    $condition: ModelNewServiceRequestConditionInput
  ) {
    deleteNewServiceRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNewContributionRequest = /* GraphQL */ `
  mutation CreateNewContributionRequest(
    $input: CreateNewContributionRequestInput!
    $condition: ModelNewContributionRequestConditionInput
  ) {
    createNewContributionRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNewContributionRequest = /* GraphQL */ `
  mutation UpdateNewContributionRequest(
    $input: UpdateNewContributionRequestInput!
    $condition: ModelNewContributionRequestConditionInput
  ) {
    updateNewContributionRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNewContributionRequest = /* GraphQL */ `
  mutation DeleteNewContributionRequest(
    $input: DeleteNewContributionRequestInput!
    $condition: ModelNewContributionRequestConditionInput
  ) {
    deleteNewContributionRequest(input: $input, condition: $condition) {
      id
      name
      email
      description
      votes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createServiceBuildingBlocks = /* GraphQL */ `
  mutation CreateServiceBuildingBlocks(
    $input: CreateServiceBuildingBlocksInput!
    $condition: ModelServiceBuildingBlocksConditionInput
  ) {
    createServiceBuildingBlocks(input: $input, condition: $condition) {
      id
      SequenceNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BuildingBlockService {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateServiceBuildingBlocks = /* GraphQL */ `
  mutation UpdateServiceBuildingBlocks(
    $input: UpdateServiceBuildingBlocksInput!
    $condition: ModelServiceBuildingBlocksConditionInput
  ) {
    updateServiceBuildingBlocks(input: $input, condition: $condition) {
      id
      SequenceNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BuildingBlockService {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteServiceBuildingBlocks = /* GraphQL */ `
  mutation DeleteServiceBuildingBlocks(
    $input: DeleteServiceBuildingBlocksInput!
    $condition: ModelServiceBuildingBlocksConditionInput
  ) {
    deleteServiceBuildingBlocks(input: $input, condition: $condition) {
      id
      SequenceNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BuildingBlockService {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRetoolOnboard = /* GraphQL */ `
  mutation CreateRetoolOnboard(
    $input: CreateRetoolOnboardInput!
    $condition: ModelRetoolOnboardConditionInput
  ) {
    createRetoolOnboard(input: $input, condition: $condition) {
      id
      ApiName
      ShortDescription
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRetoolOnboard = /* GraphQL */ `
  mutation UpdateRetoolOnboard(
    $input: UpdateRetoolOnboardInput!
    $condition: ModelRetoolOnboardConditionInput
  ) {
    updateRetoolOnboard(input: $input, condition: $condition) {
      id
      ApiName
      ShortDescription
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRetoolOnboard = /* GraphQL */ `
  mutation DeleteRetoolOnboard(
    $input: DeleteRetoolOnboardInput!
    $condition: ModelRetoolOnboardConditionInput
  ) {
    deleteRetoolOnboard(input: $input, condition: $condition) {
      id
      ApiName
      ShortDescription
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createServiceConsumptionUsage = /* GraphQL */ `
  mutation CreateServiceConsumptionUsage(
    $input: CreateServiceConsumptionUsageInput!
    $condition: ModelServiceConsumptionUsageConditionInput
  ) {
    createServiceConsumptionUsage(input: $input, condition: $condition) {
      id
      stag
      requestId
      apiId
      resourcePath
      resourceId
      httpMethod
      sourceIP
      userAgent
      accountId
      apiKey
      apiKeyId
      requestTime
      latency
      stat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      ServiceSubscription {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateServiceConsumptionUsage = /* GraphQL */ `
  mutation UpdateServiceConsumptionUsage(
    $input: UpdateServiceConsumptionUsageInput!
    $condition: ModelServiceConsumptionUsageConditionInput
  ) {
    updateServiceConsumptionUsage(input: $input, condition: $condition) {
      id
      stag
      requestId
      apiId
      resourcePath
      resourceId
      httpMethod
      sourceIP
      userAgent
      accountId
      apiKey
      apiKeyId
      requestTime
      latency
      stat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      ServiceSubscription {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteServiceConsumptionUsage = /* GraphQL */ `
  mutation DeleteServiceConsumptionUsage(
    $input: DeleteServiceConsumptionUsageInput!
    $condition: ModelServiceConsumptionUsageConditionInput
  ) {
    deleteServiceConsumptionUsage(input: $input, condition: $condition) {
      id
      stag
      requestId
      apiId
      resourcePath
      resourceId
      httpMethod
      sourceIP
      userAgent
      accountId
      apiKey
      apiKeyId
      requestTime
      latency
      stat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      ServiceSubscription {
        id
        ownerType
        keyId
        key
        usagePlan
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createServiceSubscription = /* GraphQL */ `
  mutation CreateServiceSubscription(
    $input: CreateServiceSubscriptionInput!
    $condition: ModelServiceSubscriptionConditionInput
  ) {
    createServiceSubscription(input: $input, condition: $condition) {
      id
      ownerType
      keyId
      key
      usagePlan
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Company {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      User {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateServiceSubscription = /* GraphQL */ `
  mutation UpdateServiceSubscription(
    $input: UpdateServiceSubscriptionInput!
    $condition: ModelServiceSubscriptionConditionInput
  ) {
    updateServiceSubscription(input: $input, condition: $condition) {
      id
      ownerType
      keyId
      key
      usagePlan
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Company {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      User {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteServiceSubscription = /* GraphQL */ `
  mutation DeleteServiceSubscription(
    $input: DeleteServiceSubscriptionInput!
    $condition: ModelServiceSubscriptionConditionInput
  ) {
    deleteServiceSubscription(input: $input, condition: $condition) {
      id
      ownerType
      keyId
      key
      usagePlan
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      Company {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      User {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      Service {
        id
        name
        type
        tagLine
        description
        liveStatus
        logoURL
        launchDate
        privacyPolicyURL
        owner
        openAPISpecificationUrl
        videoURL
        postmanCollectionUrl
        gatewayAPIId
        targetURL
        authType
        headerKey
        headervalue
        untitledfield
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createServiceDocument = /* GraphQL */ `
  mutation CreateServiceDocument(
    $input: CreateServiceDocumentInput!
    $condition: ModelServiceDocumentConditionInput
  ) {
    createServiceDocument(input: $input, condition: $condition) {
      id
      documentType
      documentUrl
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateServiceDocument = /* GraphQL */ `
  mutation UpdateServiceDocument(
    $input: UpdateServiceDocumentInput!
    $condition: ModelServiceDocumentConditionInput
  ) {
    updateServiceDocument(input: $input, condition: $condition) {
      id
      documentType
      documentUrl
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteServiceDocument = /* GraphQL */ `
  mutation DeleteServiceDocument(
    $input: DeleteServiceDocumentInput!
    $condition: ModelServiceDocumentConditionInput
  ) {
    deleteServiceDocument(input: $input, condition: $condition) {
      id
      documentType
      documentUrl
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPricePlan = /* GraphQL */ `
  mutation CreatePricePlan(
    $input: CreatePricePlanInput!
    $condition: ModelPricePlanConditionInput
  ) {
    createPricePlan(input: $input, condition: $condition) {
      id
      name
      description
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePricePlan = /* GraphQL */ `
  mutation UpdatePricePlan(
    $input: UpdatePricePlanInput!
    $condition: ModelPricePlanConditionInput
  ) {
    updatePricePlan(input: $input, condition: $condition) {
      id
      name
      description
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePricePlan = /* GraphQL */ `
  mutation DeletePricePlan(
    $input: DeletePricePlanInput!
    $condition: ModelPricePlanConditionInput
  ) {
    deletePricePlan(input: $input, condition: $condition) {
      id
      name
      description
      costType
      unitOfMeasure
      unit
      costDollar
      costPercentage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEventTrigger = /* GraphQL */ `
  mutation CreateEventTrigger(
    $input: CreateEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    createEventTrigger(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEventTrigger = /* GraphQL */ `
  mutation UpdateEventTrigger(
    $input: UpdateEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    updateEventTrigger(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEventTrigger = /* GraphQL */ `
  mutation DeleteEventTrigger(
    $input: DeleteEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    deleteEventTrigger(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createValueChainIdentifier = /* GraphQL */ `
  mutation CreateValueChainIdentifier(
    $input: CreateValueChainIdentifierInput!
    $condition: ModelValueChainIdentifierConditionInput
  ) {
    createValueChainIdentifier(input: $input, condition: $condition) {
      id
      name
      description
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateValueChainIdentifier = /* GraphQL */ `
  mutation UpdateValueChainIdentifier(
    $input: UpdateValueChainIdentifierInput!
    $condition: ModelValueChainIdentifierConditionInput
  ) {
    updateValueChainIdentifier(input: $input, condition: $condition) {
      id
      name
      description
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteValueChainIdentifier = /* GraphQL */ `
  mutation DeleteValueChainIdentifier(
    $input: DeleteValueChainIdentifierInput!
    $condition: ModelValueChainIdentifierConditionInput
  ) {
    deleteValueChainIdentifier(input: $input, condition: $condition) {
      id
      name
      description
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCompanySocialMediaLink = /* GraphQL */ `
  mutation CreateCompanySocialMediaLink(
    $input: CreateCompanySocialMediaLinkInput!
    $condition: ModelCompanySocialMediaLinkConditionInput
  ) {
    createCompanySocialMediaLink(input: $input, condition: $condition) {
      id
      type
      url
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCompanySocialMediaLink = /* GraphQL */ `
  mutation UpdateCompanySocialMediaLink(
    $input: UpdateCompanySocialMediaLinkInput!
    $condition: ModelCompanySocialMediaLinkConditionInput
  ) {
    updateCompanySocialMediaLink(input: $input, condition: $condition) {
      id
      type
      url
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCompanySocialMediaLink = /* GraphQL */ `
  mutation DeleteCompanySocialMediaLink(
    $input: DeleteCompanySocialMediaLinkInput!
    $condition: ModelCompanySocialMediaLinkConditionInput
  ) {
    deleteCompanySocialMediaLink(input: $input, condition: $condition) {
      id
      type
      url
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLineOfBusiness = /* GraphQL */ `
  mutation CreateLineOfBusiness(
    $input: CreateLineOfBusinessInput!
    $condition: ModelLineOfBusinessConditionInput
  ) {
    createLineOfBusiness(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLineOfBusiness = /* GraphQL */ `
  mutation UpdateLineOfBusiness(
    $input: UpdateLineOfBusinessInput!
    $condition: ModelLineOfBusinessConditionInput
  ) {
    updateLineOfBusiness(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLineOfBusiness = /* GraphQL */ `
  mutation DeleteLineOfBusiness(
    $input: DeleteLineOfBusinessInput!
    $condition: ModelLineOfBusinessConditionInput
  ) {
    deleteLineOfBusiness(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInsuranceValueChain = /* GraphQL */ `
  mutation CreateInsuranceValueChain(
    $input: CreateInsuranceValueChainInput!
    $condition: ModelInsuranceValueChainConditionInput
  ) {
    createInsuranceValueChain(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInsuranceValueChain = /* GraphQL */ `
  mutation UpdateInsuranceValueChain(
    $input: UpdateInsuranceValueChainInput!
    $condition: ModelInsuranceValueChainConditionInput
  ) {
    updateInsuranceValueChain(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInsuranceValueChain = /* GraphQL */ `
  mutation DeleteInsuranceValueChain(
    $input: DeleteInsuranceValueChainInput!
    $condition: ModelInsuranceValueChainConditionInput
  ) {
    deleteInsuranceValueChain(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserType = /* GraphQL */ `
  mutation CreateUserType(
    $input: CreateUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    createUserType(input: $input, condition: $condition) {
      id
      name
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserType = /* GraphQL */ `
  mutation UpdateUserType(
    $input: UpdateUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    updateUserType(input: $input, condition: $condition) {
      id
      name
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserType = /* GraphQL */ `
  mutation DeleteUserType(
    $input: DeleteUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    deleteUserType(input: $input, condition: $condition) {
      id
      name
      companyID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCompanyType = /* GraphQL */ `
  mutation CreateCompanyType(
    $input: CreateCompanyTypeInput!
    $condition: ModelCompanyTypeConditionInput
  ) {
    createCompanyType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCompanyType = /* GraphQL */ `
  mutation UpdateCompanyType(
    $input: UpdateCompanyTypeInput!
    $condition: ModelCompanyTypeConditionInput
  ) {
    updateCompanyType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCompanyType = /* GraphQL */ `
  mutation DeleteCompanyType(
    $input: DeleteCompanyTypeInput!
    $condition: ModelCompanyTypeConditionInput
  ) {
    deleteCompanyType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createServiceTag = /* GraphQL */ `
  mutation CreateServiceTag(
    $input: CreateServiceTagInput!
    $condition: ModelServiceTagConditionInput
  ) {
    createServiceTag(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateServiceTag = /* GraphQL */ `
  mutation UpdateServiceTag(
    $input: UpdateServiceTagInput!
    $condition: ModelServiceTagConditionInput
  ) {
    updateServiceTag(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteServiceTag = /* GraphQL */ `
  mutation DeleteServiceTag(
    $input: DeleteServiceTagInput!
    $condition: ModelServiceTagConditionInput
  ) {
    deleteServiceTag(input: $input, condition: $condition) {
      id
      name
      serviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      description
      currentPartner
      logoUrl
      websiteUrl
      email
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      CompanySocialMediaLinks {
        nextToken
        startedAt
      }
      LineOfBusiness {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      InsuranceValueChain {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      UserTypes {
        nextToken
        startedAt
      }
      CompanyType {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      description
      currentPartner
      logoUrl
      websiteUrl
      email
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      CompanySocialMediaLinks {
        nextToken
        startedAt
      }
      LineOfBusiness {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      InsuranceValueChain {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      UserTypes {
        nextToken
        startedAt
      }
      CompanyType {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      description
      currentPartner
      logoUrl
      websiteUrl
      email
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      CompanySocialMediaLinks {
        nextToken
        startedAt
      }
      LineOfBusiness {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      InsuranceValueChain {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      UserTypes {
        nextToken
        startedAt
      }
      CompanyType {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      role
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      role
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      role
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      type
      tagLine
      description
      liveStatus
      logoURL
      launchDate
      privacyPolicyURL
      owner
      openAPISpecificationUrl
      videoURL
      postmanCollectionUrl
      gatewayAPIId
      targetURL
      authType
      headerKey
      headervalue
      untitledfield
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ServiceDocuments {
        nextToken
        startedAt
      }
      PricePlan {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      EventTriggers {
        nextToken
        startedAt
      }
      ValueChainIdentifiers {
        nextToken
        startedAt
      }
      ServiceTags {
        nextToken
        startedAt
      }
      OwnerCompany {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      OwnerUser {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      type
      tagLine
      description
      liveStatus
      logoURL
      launchDate
      privacyPolicyURL
      owner
      openAPISpecificationUrl
      videoURL
      postmanCollectionUrl
      gatewayAPIId
      targetURL
      authType
      headerKey
      headervalue
      untitledfield
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ServiceDocuments {
        nextToken
        startedAt
      }
      PricePlan {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      EventTriggers {
        nextToken
        startedAt
      }
      ValueChainIdentifiers {
        nextToken
        startedAt
      }
      ServiceTags {
        nextToken
        startedAt
      }
      OwnerCompany {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      OwnerUser {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      type
      tagLine
      description
      liveStatus
      logoURL
      launchDate
      privacyPolicyURL
      owner
      openAPISpecificationUrl
      videoURL
      postmanCollectionUrl
      gatewayAPIId
      targetURL
      authType
      headerKey
      headervalue
      untitledfield
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ServiceDocuments {
        nextToken
        startedAt
      }
      PricePlan {
        id
        name
        description
        costType
        unitOfMeasure
        unit
        costDollar
        costPercentage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      EventTriggers {
        nextToken
        startedAt
      }
      ValueChainIdentifiers {
        nextToken
        startedAt
      }
      ServiceTags {
        nextToken
        startedAt
      }
      OwnerCompany {
        id
        name
        description
        currentPartner
        logoUrl
        websiteUrl
        email
        phone
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      OwnerUser {
        id
        name
        email
        phone
        role
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createLastDIBData = /* GraphQL */ `
  mutation CreateLastDIBData(
    $input: CreateLastDIBDataInput!
    $condition: ModelLastDIBDataConditionInput
  ) {
    createLastDIBData(input: $input, condition: $condition) {
      id
      quoteNumber
      carrierCode
      isWining
      quoteExpiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLastDIBData = /* GraphQL */ `
  mutation UpdateLastDIBData(
    $input: UpdateLastDIBDataInput!
    $condition: ModelLastDIBDataConditionInput
  ) {
    updateLastDIBData(input: $input, condition: $condition) {
      id
      quoteNumber
      carrierCode
      isWining
      quoteExpiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLastDIBData = /* GraphQL */ `
  mutation DeleteLastDIBData(
    $input: DeleteLastDIBDataInput!
    $condition: ModelLastDIBDataConditionInput
  ) {
    deleteLastDIBData(input: $input, condition: $condition) {
      id
      quoteNumber
      carrierCode
      isWining
      quoteExpiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      name
      email
      type
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      name
      email
      type
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      name
      email
      type
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
