import { AmplifyConfirmSignUp } from "@aws-amplify/ui-react";
import { IonContent, IonCard, IonItem, IonLabel, IonInput, IonFooter, IonCardContent, IonButton, IonRouterLink } from "@ionic/react";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MessageBox } from "../../components/MessageBox";


export const RegisterConfirm = (props:{handler?:any; email?:any}) => {
  const {control, handleSubmit} = useForm();
  const [msg, setMsg] = useState<{color?:any; message?:any;}>({})
  const confirm = (values:any) => {
    console.log(values);
    props.handler({loading: true});
    Auth.confirmSignUp(values.email, values.code, )
      .then( (resp) => {
        props.handler({view: 'signin'})
      })
      .catch( (err) => {
        console.error(err);
        if (err.code == 'UserNotConfirmedException') {
          props.handler({view: 'register-confirm'});
        } else {
          setMsg({
            color: 'danger',
            message: err.message
          })
        }
      })
      .finally( () => {
        props.handler({loading: false});
      })
  };
  return (
    <>
      <IonContent color="light">
        <IonCard>
          <IonCardContent>
            Your account has not been confirmed. We've just sent you a confirmation code you can use to confirm your email.
          </IonCardContent>
          <IonItem lines="full">
            <IonLabel position="floating">Email</IonLabel>
            <Controller
              control={control}
              name="email"
              render={
                ({field: {value, onChange}}) => 
                <IonInput required value={props.email || value} onIonChange={( { detail: { value } } ) => onChange(value)} type="email"></IonInput>
              } 
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">Verification Code</IonLabel>
            <Controller
              control={control}
              name="code"
              render={
                ({field: {value, onChange}}) => 
                <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="number" max="999999" maxlength={6} minlength={6}></IonInput>
              } 
            />
          </IonItem>
        </IonCard>
        <IonItem style={{'--background': 'transparent'}} lines="none">
          <IonLabel>Lost your code? <IonRouterLink style={{cursor: 'pointer'}} onClick={
            () => {
              Auth.resendSignUp(props.email)
                .then( (resp) => {
                  console.log(resp);
                  setMsg({
                    color: 'dark',
                    message: "We've sent a new confirmation code"
                  })
                })
                .catch( (error) => {
                  setMsg({
                    color: 'danger',
                    message: error.message
                  })
                })
            }
          }>Resend code</IonRouterLink></IonLabel>
        </IonItem>
        { msg.message ? <MessageBox color={msg.color} message={msg.message} /> : <></> }
      </IonContent>
      <IonFooter>
        <IonItem button={true} lines="none" color="primary" type="submit" detail={true} onClick={handleSubmit(confirm)}>
          Confirm
        </IonItem>
      </IonFooter>
    </>
  )
}