import { IonSearchbar, IonCard, IonItem, IonAvatar, IonNote, IonLabel, IonSpinner, IonItemDivider, useIonViewWillEnter } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { API } from 'aws-amplify';
import { searchServices } from '../../graphql/queries';

const MarketplaceSearch = (props:any) => {
  const [searching, setSearching] = useState(false)
  const [results, setResults] = useState<{items?:any[]; nextToken?:string; total?:number; value?:string;}>({
    items: []
  });
  useIonViewWillEnter(() => {
    (document.getElementById("mpSearchbar") as HTMLIonSearchbarElement).value = "";
    setResults({items: [], value: ''});
  });
  function extractContent(s:string) {
    
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  return (<>
    <IonSearchbar id="mpSearchbar" searchIcon={searching ? 'ellipsis' : 'search-outline'} style={{overflow: 'visible'}} className="ion-no-padding" onIonInput={() => setSearching(true)} onIonChange={async ({detail: { value }}) => {
      if (value) {
        const services = await API.graphql({
          query: searchServices,
          variables: {
            filter: {
              or: [
                { name: {matchPhrasePrefix: value} },
                { description: {matchPhrasePrefix: value} },
              ]
            },
            limit: 5
          },
          authMode: ('API_KEY' as any)
        });
        setResults({...(services as any).data.searchServices, value});
      } else {
        setResults({value, items: []});
      }
      setSearching(false);
    }}>
      {searching ? <IonSpinner name="crescent" style={{position: 'absolute', left: '10px'}}></IonSpinner> : null}
      {results.value ?
        <IonCard class="ion-no-margin ion-no-padding" style={{position: 'absolute', top: '100%', width: '100%', maxHeight: 'calc(50vh - 50px)', overflowY: 'auto', zIndex: 99}}>
          {
            results.items && results.items.length > 0 ? results.items?.map(s => {
              let desc = extractContent(s.description);
              if (desc.length > 126) {
                desc = desc.substring(0,126) + '...'
              }
              return (
                <IonItem key={s.id} button={true} routerLink={'/service/' + s.id}>
                  <IonAvatar slot="start">
                    <img src={s.logoURL} />
                  </IonAvatar>
                  <IonLabel class="ion-text-wrap">
                    <h1>{s.name}</h1>
                    <p><IonNote>{desc}</IonNote></p>
                  </IonLabel>
                </IonItem>
              )
            }) : results.items && results.items.length < 3 ?
              <>
                <IonItemDivider>Can't find what you're looking for?</IonItemDivider>
                <IonItem button={true} detail={true} color="light" routerLink="/marketplace">
                  Browse Marketplace
                </IonItem>
                <IonItem button={true} detail={true} color="primary" routerLink="/request">
                  Request New Service
                </IonItem>
              </>
            : null
          }
        </IonCard>
        : null
      }
    </IonSearchbar>
  </>);
}

export default MarketplaceSearch;