import { IonHeader, IonToolbar, IonButtons, IonTitle, IonMenuButton } from "@ionic/react";
import AuthButton from "../auth/AuthButton";
import Logo from "./logo/logo";

const Header = (props:{translucent:boolean; user:any;}) => {
  return (
    <>
      <IonHeader translucent={props.translucent}>
        <IonToolbar style={{"--background": "transparent"}}>
          <IonButtons slot="start">
            <IonMenuButton menu="mainMenu" autoHide={true}></IonMenuButton>
            <IonTitle>
              <Logo height="75px" />
            </IonTitle>
          </IonButtons>
          <IonButtons slot="end">
            <IonTitle>
              <AuthButton user={props.user} />
            </IonTitle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </>
  )
}

export default Header;