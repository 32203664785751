import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRouterOutlet, IonRow, IonSegment, IonSegmentButton, IonTab, IonTabBar, IonTabButton, IonTabs, IonToolbar } from "@ionic/react"
import { Redirect, Route, useRouteMatch } from "react-router";
import { APIKeys } from "./APIKeys";
import { Billing } from "./Billing";
import { Profile } from "./Profile";
import { Settings } from "./Settings";

const ProfilePage = (props:any) => {
  const segments = [
    {label: 'Profile', anchor: 'profile'},
    {label: 'Settings', anchor: 'settings'},
    {label: 'API Keys', anchor: 'api-keys'},
    {label: 'Billing', anchor: 'billing'},
  ];
  const match = useRouteMatch();
  return (
    <IonPage>
      <IonTabs>
        <IonRouterOutlet>
          <Route path={`/user/profile`} exact>
            <Profile />
          </Route>
          <Route path={`/user/settings`} exact>
            <Settings />
          </Route>
          <Route path={`/user/api-keys`} exact>
            <APIKeys />
          </Route>
          <Route path={`/user/billing`} exact>
            <Billing />
          </Route>
          <Redirect exact path={`/user`} to={`/user/profile`} />
        </IonRouterOutlet>
        <IonTabBar slot="top" color="medium">
          {segments.map(s =>  <IonTabButton key={s.anchor} href={`/user/${s.anchor}`} tab={s.anchor}>{s.label}</IonTabButton>)}
        </IonTabBar>
      </IonTabs>
    </IonPage>
  )
}

export default ProfilePage;