import {
  Link,
  Redirect,
  Route, useHistory
} from "react-router-dom";
import { IonApp, IonContent, IonItem, IonLabel, IonPage, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Landing from './pages/Landing';

import About from './pages/About';
import Marketplace from './pages/marketplace/Marketplace';
import Contact from './pages/Contact';
import Header from "./components/Header";
import ServicePage from "./pages/marketplace/ServicePage";
import { useRef, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import Explore from "./pages/explore/Explore";
import SideMenu from "./components/SideMenu";
import SignoutButton from "./auth/SingoutButton";
import ProfilePage from "./profile/ProfilePage";

const App: React.FC = () => {
  const [fs, setFs] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);
  const history = useHistory();
  const initRef = useRef(false);
  const updatingAuth = useRef(false);
  const listener = async (data:any) => {
    if (!updatingAuth.current) {
      updatingAuth.current = true;
      switch (data.payload.event) {
          case 'signIn':
              setUser(await Auth.currentAuthenticatedUser());
              break;
          case 'signOut':
              setUser(null);
              break;
      }
      updatingAuth.current = false;
    }
  }
  if (!initRef.current) {
    Auth.currentAuthenticatedUser().then( (usr) => {
      setUser(usr);
      initRef.current = true;
    });
    Hub.listen('auth', listener);
  }
  
  return (
    <IonReactRouter>
      <IonApp>
        <IonPage>
          <Header translucent={fs} user={user} />
          <IonContent slot="fixed" scrollY={false}>
            <IonSplitPane when={false}>
              <SideMenu contentId="main" menuId="mainMenu" side="start" swipeGesture={false} options={[
                {label: 'For Consumers', divider: true},
                {label: 'Marketplace', url: '/marketplace'},
                {label: 'Explore', url: '/explore'},
                {label: 'For Contributors', divider: true},
                {label: 'Contribute', url: '/contribute'},
                {label: '', divider: true, color: 'light'},
                {label: 'About', url: '/about'},
                {label: 'Contact Us', url: '/contact'},
              ]}>
                <IonItem lines="none">
                  <IonLabel class="ion-text-center">
                    <Link to="/terms-of-service">Terms of Service</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
                  </IonLabel>
                </IonItem>
              </SideMenu>
              {
                user ? (
                  <SideMenu contentId="main" menuId="userMenu" side="end" swipeGesture={false} options={[
                    {label: 'Profile', url: '/user/profile'},
                    {label: 'Settings', url: '/user/settings'},
                    {label: 'API Keys', url: '/user/api-keys'},
                    {label: 'Billing', url: '/user/billing'},
                    {label: 'My Consumption', divider: true},
                    {label: 'Consumption Dashboard', url: '/user/dashboard'},
                    {label: 'My Services', divider: true},
                    {label: 'Company Profile', url: '/contributor/company'},
                    {label: 'Services Dashboard', url: '/contributor/dashboard'},
                  ]}>
                    <SignoutButton history={history} />
                  </SideMenu>
                ) : null
              }
              <IonPage id="main">
                <IonRouterOutlet>
                  {/* PUBLIC ROUTES */}
                  <Route exact path="/">
                    <Landing fsToggle={setFs} />
                  </Route>
                  <Route exact path="/explore">
                    <Explore />
                  </Route>
                  <Route exact path="/about">
                    <About />
                  </Route>
                  <Route exact path="/contact">
                    <Contact />
                  </Route>
                  <Route exact path="/contribute">
                    <Contact type="contributor" />
                  </Route>
                  <Route exact path="/request">
                    <Contact type="request"/>
                  </Route> 
                  <Redirect path="/contributerequest" to="/contribute" />
                  
                  {/* MARKETPLACE PAGES */}
                  <Route path='/service/:index' component={ServicePage} />
                  <Route exact path="/marketplace">
                    <Marketplace history={[]} />
                  </Route>
                  <Route exact path="/user/:tab">
                    <ProfilePage />
                  </Route>
                </IonRouterOutlet>
              </IonPage>
            </IonSplitPane>
          </IonContent>
        </IonPage>
      </IonApp>
    </IonReactRouter>
  )
};

export default App;
