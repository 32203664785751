import  { Component } from 'react';
import person from '../../styleassets/images/user.png';
import eyeicon from '../../styleassets/images/view.png'
import bookmark from '../../styleassets/images/bookmark.png'

 


class MarketPlaceCardBox extends Component<any> {
    render() {     
        return (
            <>
             
                <div className="feature-vehicle-box" >
                    <div className="feature-icon" title="">
                        <p > <img src={eyeicon} alt="Views" width="13" height="19" /></p>
                    </div>
                    <div className="feature-icon" title="">
                        <p><img src={person} alt="Consumers" width="17" height="17" /></p>
                    </div>
                    <div className="feature-icon" title="">
                        <p><img src={bookmark} alt="Bookmark" width="17" height="17" />   </p>
                    </div>
                </div>
            </>
        );
    }
}

export default MarketPlaceCardBox;