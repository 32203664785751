import { IonContent, IonCard, IonFooter, IonInput, IonItem, IonLabel, IonRouterLink } from "@ionic/react"
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from "react-router";
import { MessageBox } from "../../components/MessageBox";

const SigninForm = (props:{handler?:any}) => {
  const { control, handleSubmit } = useForm();
  const [msg, setMsg] = useState<{color?:any; message?:any;}>({})
  const signin = (values:any) => {
    console.log(values);
    props.handler({loading: true});
    Auth.signIn(values.email, values.password)
      .then( (resp) => {
        props.handler({
          view: 'redirect'
        })
      })
      .catch( (err) => {
        console.error(err);
        if (err.code == 'UserNotConfirmedException') {
          props.handler({
            email: values.email,
            view: 'register-confirm'
          });
        } else {
          setMsg({
            color: 'danger',
            message: err.message
          })
        }
      })
      .finally( () => {
        props.handler({loading: false});
      })
  };
  return (
    <>
      <IonContent color="light">
        <IonCard>
          <IonItem lines="full">
            <IonLabel position="floating">Email</IonLabel>
            <Controller
              control={control}
              name="email"
              render={
                ({field: {value, onChange}}) => 
                <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="email"></IonInput>
              } 
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">Password</IonLabel>
            <Controller
              control={control}
              name="password"
              render={
                ({field: {value, onChange}}) => 
                <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="password"></IonInput>
              } 
            />
          </IonItem>
        </IonCard>
        <IonItem style={{'--background': 'transparent'}} lines="none">
          <IonLabel>Can't sign in? <IonRouterLink style={{cursor: 'pointer'}} onClick={
            () => {
              props.handler({
                view: 'forgot'
              });
            }
          }>Reset password</IonRouterLink></IonLabel>
        </IonItem>
        { msg.message ? <MessageBox color={msg.color} message={msg.message} /> : <></> }
      </IonContent>
      <IonFooter>
        <IonItem button={true} lines="none" color="primary" type="submit" detail={true} onClick={handleSubmit(signin)}>
          Sign In
        </IonItem>
      </IonFooter>
    </>
  )
}

export default SigninForm;