import { useState } from 'react';
import { IonButton, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonButtons, IonIcon } from '@ionic/react';
import SigninForm from './signin/SigninForm';
import RegisterForm from './register/RegisterForm';
import { close } from 'ionicons/icons';
import { RegisterConfirm } from './register/RegisterConfirm';
import SigninForgot from './signin/SigninForgot';

const AuthModal = (props:{onDismiss:Function; history?: any; redirect?:string;}) => {
  const [state, setState] = useState<{view?:string|undefined; redirect?:string|undefined; email?:string|undefined;}>({
    view: 'signin'
  });

  const handleChildEvent = (props:any) => {
    if (typeof(props.loading) == 'boolean') {
    } else {
      setState(props);
    }
  }
  handleChildEvent.bind(this);
  
  let header = (
    <IonHeader>
      <IonToolbar>
        <IonSegment value={state.view} onIonChange={( { detail: {value} } ) => {
          setState({view: value});
        }}>
          <IonSegmentButton value="signin">Sign In</IonSegmentButton>
          <IonSegmentButton value="register">Register</IonSegmentButton>
        </IonSegment>
        <IonButtons slot="end">
          <IonButton onClick={() => props.onDismiss()}>
            <IonIcon icon={close}></IonIcon>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );

  let content;
    switch (state.view) {
      case 'register':
        content = <RegisterForm handler={handleChildEvent} />;
        break;
      case 'register-confirm':
        content = <RegisterConfirm handler={handleChildEvent} email={state.email} />;
        break;
      case 'forgot':
        content = <SigninForgot handler={handleChildEvent} />;
        break;
      case 'redirect':
        props.onDismiss();
        props.history.push(props.redirect || '/marketplace');
        break;
      case 'signin':
      default:
        content = <SigninForm handler={handleChildEvent} />;
    }
    return (<>
      {header}
      {content}
    </>)
}

export default AuthModal;