import React, { Component } from 'react'
import { RedocStandalone } from 'redoc';

class ServiceDocumentation extends Component <any>{
    constructor(props:any) {
        super(props);
        this.state = {         
        }        
    }

    componentDidMount = () => {     
        this.setState({           
        })        
    }

    render() {
    //   console.log(this.props.openAPISpecificationUrl)
        return (
            <> 
                <div>
                    <RedocStandalone
                        specUrl={this.props.openAPISpecificationUrl}
                        options={{
                            nativeScrollbars: true,
                            theme: { colors: { primary: { main: '#dd5522' } } },
                        }}
                    />
                </div>
            </>
        );
    }
}
export default ServiceDocumentation;