/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2c19a930-e82a-48c9-b8de-02fd9b4c51b5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AjO3yVwDA",
    "aws_user_pools_web_client_id": "5fensc4idhplb9k5nv7a05i1rv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fngtypbnpzhwtn33bo75wreqte.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-grof6tdzirdolpotodcv3m24fu",
    "aws_user_files_s3_bucket": "insurancegig-bucket141950-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
