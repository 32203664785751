import { IonButton, IonContent, IonCard, IonFooter, IonInput, IonItem, IonLabel, IonRouterLink } from "@ionic/react"
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { MessageBox } from "../../components/MessageBox";

const SigninForgot = (props:{handler?:any}) => {
  const { control, handleSubmit } = useForm();
  const [msg, setMsg] = useState<{color?:any; message?:any;}>({})
  const [showCode, setShowCode] = useState<boolean>(false);
  const forgot = (values:any) => {
    console.log(values);
    props.handler({loading: true});
    Auth.forgotPassword(values.email)
      .then( (resp) => {
        setShowCode(true);
      })
      .catch( (error) => {
        setMsg({
          color: 'danger',
          message: error.message
        })
      })
      .finally( () => {
        props.handler({loading: false});
      })
  };

  const submit = (values:any) => {
    console.log(values);
    Auth.forgotPasswordSubmit(values.email, values.code, values.password)
      .then( (resp) => {
        props.handler({
          view: 'signin'
        })
      })
      .catch( (error) => {
        setMsg({
          color: 'danger',
          message: error.message
        })
      })
  }
  return (
    <>
      <IonContent color="light" style={{display: 'flex', alignItems: 'center'}}>
        <IonCard>
          <IonItem lines="full" disabled={showCode}>
            <IonLabel position="floating">Email</IonLabel>
            <Controller
              control={control}
              name="email"
              render={
                ({field: {value, onChange}}) => 
                <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="email"></IonInput>
              } 
            />
          </IonItem>
          {
            showCode ? <>
            <IonItem lines="full">
              <IonLabel position="floating">Verification Code</IonLabel>
              <Controller
                control={control}
                name="code"
                render={
                  ({field: {value, onChange}}) => 
                  <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="number" max="999999" maxlength={6} minlength={6}></IonInput>
                } 
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="floating">New Password</IonLabel>
              <Controller
                control={control}
                name="password"
                render={
                  ({field: {value, onChange}}) => 
                  <IonInput required value={value} onIonChange={( { detail: { value } } ) => onChange(value)} type="password"></IonInput>
                } 
              />
            </IonItem>
            </>
            : null
          }
        </IonCard>
        <IonItem style={{'--background': 'transparent'}} lines="none">
          <IonRouterLink style={{
            cursor: 'pointer'
          }} onClick={
            () => {
              props.handler({
                view: 'signin'
              });
            }
          }>Back to sign in</IonRouterLink>
        </IonItem>
        { msg.message ? <MessageBox color={msg.color} message={msg.message} /> : <></> }
      </IonContent>
      <IonFooter>
        <IonItem button={true} lines="none" color="primary" type="submit" detail={true} onClick={ showCode ? handleSubmit(submit) : handleSubmit(forgot)}>
          {showCode ? 'Confirm Reset' : 'Reset Password'}
        </IonItem>
      </IonFooter>
    </>
  )
}

export default SigninForgot;