import { IonButton, IonIcon, IonMenuToggle } from "@ionic/react";
import { person } from "ionicons/icons";
import React from "react";
import { SigninButton } from "./signin/SigninButton";

const AuthButton = (props:any) => {
  if (props.user) {
    return (
      <IonMenuToggle menu="userMenu" autoHide={false} onClick={(ev)=>console.log(ev)}>
        <IonButton fill="outline">
          <IonIcon slot="icon-only" icon={person}></IonIcon>
        </IonButton>
      </IonMenuToggle>
    )
  } else {
    return <SigninButton />
  }
};

export default AuthButton;