import { Component } from 'react';
import MarketplaceCard from './MarketplaceCard';
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import '../../styleassets/css/bootstrap.min.css';
import '../../styleassets/css/Marketplace.css';
import '../../styleassets/css/responsive.css';
import gql from 'graphql-tag'
import { IonButton, IonContent, IonLabel, IonPage, IonSearchbar, IonSpinner } from '@ionic/react';
import MarketPlaceSpinner from '../../components/Spinner';


class Marketplace extends Component<
    { history: any; },
    { apiType: any; loading: boolean; services: any; searchText: string }
> {

    constructor(props: { history: any; }) {
        super(props);
        this.state = {
            services: [],
            loading: true,
            searchText: '',
            apiType: 'All'
        }
    }
    componentDidMount() {
        this.filterServices( );
    }

    onApiTypeChange = (e: any) => {
        this.setState({
            apiType: e.target.value
        });
    }

    onSearchChange = async (e: any) => {

        this.setState({
            searchText: e.target.value
        });
    }

    onAppluFilterClick = async () => {

        this.filterServices( )
    }
    filterServices = async ( ) => {

        this.setState({
            loading: true
        });

        let marketplaceServices: any = gql`
        query listListingPageService( $filter: ModelServiceFilterInput) {
            listServices(filter:$filter) {
              items {
                name
                logoURL
                liveStatus
                tagLine
                type
                id
                ServiceTags {
                  items {
                    name
                  }
                }
                OwnerCompany {
                  name
                }
              }
            }
          }
          
      ` ;

        let servicesDataQuery: any
        if (this.state.apiType == "All") {
            if (this.state.searchText == "") {
                servicesDataQuery = await API.graphql({
                    query: marketplaceServices,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,

                });
            } else {
                servicesDataQuery = await API.graphql({
                    query: marketplaceServices,
                    variables: {
                        filter: {
                            or: [
                                { name: { contains: this.state.searchText } },
                                { description: { contains: this.state.searchText } },
                            ]
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,

                });
            }
        }
        else {
            if (this.state.searchText == "") {
                servicesDataQuery = await API.graphql({
                    query: marketplaceServices,
                    variables: {
                        filter: {
                            type: { eq: this.state.apiType }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,

                });
            } else {
              
                
                servicesDataQuery = await API.graphql({
                    query: marketplaceServices,
                    variables: {
                        
                        filter: {
                            and: [
                              { name: {contains: this.state.searchText} },
                              { type: {eq: this.state.apiType} },
                            ]
                          },
 

                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,

                });
            }


        }

        const services = servicesDataQuery.data.listServices.items;
        
        this.setState({
            services: services,
            loading: false
        });
        
    }

    render() {
        return (
            <IonPage>
                <IonContent color="light">
                    <section className="offer-box-section" style={{backgroundColor: 'white'}}>
                        <div className="container-fluid">
                            <div className="offer-filter-wrap" style={{ padding: '16px' }}>
                                <h3>{this.state.services.length} APIs</h3>
                                <div>
                                    <label>API Type</label>
                                    <select name="FilterApiType" className="filter-box" onChange={this.onApiTypeChange} defaultValue={'All'}>
                                        <option disabled={false} value="All">All</option>
                                        <option disabled={false} value="SINGLE">Single</option>
                                        <option disabled={false} value="BUNDLED">Workflow</option>
                                    </select>
                                </div>
                                &emsp;

                                <div>
                                    <label>Search API</label><br />
                                    <IonSearchbar id="mpSearchbar" searchIcon={this.state.loading ? 'ellipsis' : 'search-outline'} onIonInput={this.onSearchChange} style={{ overflow: 'visible' }} className="ion-no-padding" />

                                </div>

                                <div style={{ paddingTop: "30px" }}>
                                    <IonButton onClick={this.onAppluFilterClick} >
                                        <IonLabel>Apply Filter</IonLabel>
                                    </IonButton>
                                </div>                            </div>
                        </div>
                    </section>
                    

                    <section className="offer-box-section">
                        <div className="container-fluid">
                            {
                                (this.state.loading ?
                                    <MarketPlaceSpinner />
                                    :
                                    this.state.services.length >0?

                                    <div className="offer-box-wrapper">
                                        {

                                            this.state.services.map((model: any, index: any) => {
                                                return (
                                                    <MarketplaceCard key={model.id} model={model} />
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className="offer-box-wrapper" >
                                    <a href="/request">Can't find what you're looking for? Request new service</a>
                                    </div>
                                )
                            }
                        </div>
                    </section>
                </IonContent>
            </IonPage>


        );
    }
}

export default Marketplace;