import { IonItem, IonLabel } from "@ionic/react"

export const MessageBox = (props:{color:string; message:string;}) => {
  return (
    <IonItem lines="none">
      <IonLabel color={props.color} className="ion-text-wrap">
        {props.message}
      </IonLabel>
    </IonItem>
  )
}