import { IonPage } from "@ionic/react";

const APIKeys = () => {
  return (<IonPage>API Keys</IonPage>)
}

const APIKeysEdit = () => {
  return (<></>)
}

export {APIKeys, APIKeysEdit};