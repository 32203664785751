import { IonInput } from '@ionic/react';
import { useCallback, useRef } from 'react';
import IMask from 'imask';

const MaskedInput = (props: {mask:IMask.AnyMaskedOptions;value:any;onChange:any;properties:any;}) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, props.mask).on('accept', (e: any) => {
      props.onChange(mask.value);
    }).on('complete', (e: any) => {
      props.onChange(mask.value);
    });
    maskRef.current = mask;
  }, []);

  return (
    <IonInput value={props.value} ref={inputCallback} {...props.properties} />
  )
}

export default MaskedInput;