import { IonMenu, IonContent, IonMenuToggle, IonItem, IonFooter, IonLabel, IonIcon, IonItemDivider } from "@ionic/react";

const SideMenu = (props:{options:any[];contentId:string;menuId:string;children?:any;side?:"start"|"end";swipeGesture?:boolean;color?:string;}) => {
  return (
    <IonMenu contentId={props.contentId} menuId={props.menuId} id={props.menuId} side={props.side} swipeGesture={props.swipeGesture || true}>
      <IonContent color={props.color || 'light'}>
        {
          props.options.map( (value, index, array) => {
            if (value.divider) {
              return (
                <IonItemDivider key={'divider'+index} color="light">{value.label}</IonItemDivider>
              )
            }
            return (
              <IonMenuToggle menu={props.menuId} autoHide={false} key={props.menuId+index}>
                <IonItem lines="full" button={true} detail={true} routerLink={value.url} color={props.color}>
                  {value.icon ? <IonIcon icon={value.icon} slot="start" /> : null}
                  <IonLabel>{value.label}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          })
        }
      </IonContent>
      <IonFooter>
        {props.children}
      </IonFooter>
    </IonMenu>
  )
}

export default SideMenu;