import { IonContent, IonPage } from "@ionic/react";

const Profile = () => {
  return (
    <IonPage>
      <IonContent>
        
      </IonContent>
    </IonPage>
  )
}

const ProfileEdit = () => {
  return (<></>)
}

export {Profile, ProfileEdit};