import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import MarketPlaceSpinner from '../../components/Spinner';
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import gql from 'graphql-tag'

interface RouteParams { index: string }

export default class ServiceDetails extends Component<{ serviceId: any;serviceApiData:any },{ service: any; loading: boolean; apiId: any; bundleservices: any }>  {
    constructor(props: any) {
        super(props);
        this.state = {
            service: {},
            loading: false,
            apiId: null,
            bundleservices: [],

        }
    }
    componentDidMount() {
        this.getService(this.props.serviceId);
    }

    componentDidUpdate(prevprops: any) {
        if (this.props.serviceId != prevprops.serviceId) {
            this.getService(this.props.serviceId);
        }
    }

    getService = async (id: any) => {

        this.setState({
            loading: true
        });

        let marketplaceServices: any = gql`
        query listListingPageService( $filter: ModelServiceFilterInput) {
            listServices(filter:$filter) {
              items {
                name
                description
                logoURL
                liveStatus
                tagLine
                launchDate
                type
                id
                openAPISpecificationUrl
                ServiceTags {
                  items {
                    name
                  }
                }
                OwnerCompany {
                  name
                }
              }
            }
          }
          
      ` ;

        let servicesDataQuery: any;
        servicesDataQuery = await API.graphql({
            query: marketplaceServices,
            variables: {
                filter: {
                    id: { eq: id }
                }
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,

        });

        const services = servicesDataQuery.data.listServices.items;
        console.log(services);
        if (services.length > 0) {
            this.setState({
                service: services[0],
                loading: false,
            });
        }
        if(this.state.service.type=="BUNDLED")
        {
            this.props.serviceApiData(true,this.state.service.openAPISpecificationUrl);
        }
        else
        {
            this.props.serviceApiData(false,this.state.service.openAPISpecificationUrl);
        }
    }

    render() {
        
        return (
            <>
                {
                    this.state.loading ?
                        <MarketPlaceSpinner />
                        :
                        <>
                            <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>
                                <div className="container-fluid" >
                                    <div className="box-shadow-white-box-a ">
                                        <div className="">
                                            <div className="row flex-reversea">
                                                <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                                    <div className="pickup-location-box">
                                                        <h5 className="font-bold mb-3 mt-2 ">{this.state.service.name}</h5>
                                                        <div className="pickup-location-flex">

                                                            <div className="pickup-location-flex-content">
                                                                <h3 className="font-bold mb-3 mt-2 ">{this.state.service.tagLine}</h3>
                                                                <span>
                                                                    {(this.state.service.OwnerCompany != null) ? this.state.service.OwnerCompany.name : null}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                                    <img src={this.state.service.logoURL} alt={this.state.service.name} className="img-fluid rounded-xl" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="box-shadow-white-box-section grey-bg pb-4" style={{ backgroundColor: "#f5f2f2" }}>
                                <div className="container-fluid" >
                                    <div >
                                        <div className="row" >
                                            <div className="col-lg-7 p-3">
                                                <div className="box-shadow-white-box" >
                                                    <h5 className="font-weight-bold">Description</h5>

                                                    <div dangerouslySetInnerHTML={{ __html: this.state.service.description }} />

                                                </div>
                                                <br></br>
                                            </div>
                                            <div className="col-lg-5 p-3">
                                                <div className="box-shadow-white-box" >
                                                    <h5 className="font-weight-bold">Status</h5>

                                                    <div dangerouslySetInnerHTML={{ __html: this.state.service.liveStatus }} />
                                                    <br />
                                                    <h5 className="font-weight-bold">Launch Date</h5>

                                                    <div dangerouslySetInnerHTML={{ __html: this.state.service.launchDate }} />

                                                </div>
                                                <br></br>
                                                <div className="box-shadow-white-box" >
                                                    <h5 className="font-weight-bold">Category</h5>

                                                    <div dangerouslySetInnerHTML={{ __html: this.state.service.liveStatus }} />


                                                </div>
                                                <br></br>
                                                <div className="box-shadow-white-box" >
                                                    <h5 className="font-weight-bold">Views</h5>
                                                    <ul className="check-box-listing ">
                                                        <li>
                                                            <span>
                                                                10
                                                            </span>

                                                        </li>
                                                    </ul>


                                                    <br />
                                                    <h5 className="font-weight-bold">Review Stars</h5>
                                                    <ul className="check-box-listing ">
                                                        <li>
                                                            <span>
                                                                10
                                                            </span>

                                                        </li>
                                                    </ul>
                                                    <br />
                                                    <h5 className="font-weight-bold">Number of consuming parties</h5>
                                                    <ul className="check-box-listing ">
                                                        <li>
                                                            <span>
                                                                10
                                                            </span>

                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </section>
                        </>
                }
            </>
        )
    }
}
